import React from 'react';
import PropTypes from 'prop-types';

import autobind from 'common/decorators/autobind.js';
import {AnalyticsStoreContext} from 'core/stores/RootStore.js';
import Link from 'core/components/Link.js';

export default class WebsiteLink extends React.Component {
    static contextType = AnalyticsStoreContext;

    static propTypes = {
        analyticsEventData: PropTypes.shape({
            eventName: PropTypes.string,
            eventUrl: PropTypes.string,
            data: PropTypes.shape({
                [PropTypes.string]: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                ]),
            }).isRequired,
        }),
        href: PropTypes.string.isRequired,
    };

    @autobind
    trackWebsiteClick() {
        const {analyticsEventData, href} = this.props;
        if (!analyticsEventData) {
            return;
        }
        const {eventName, eventUrl, data} = analyticsEventData;
        data.website = href;
        if (eventUrl) {
            this.context.genericTrackEvent(eventUrl, data);
        } else {
            this.context.trackEvent(eventName, data);
        }
    }

    render() {
        return (
            <Link
                action={this.trackWebsiteClick}
                href={this.props.href}
                isExternalLink={true}
            >
                {'Visit website'}
            </Link>
        );
    }
}
