import React from 'react';
import PropTypes from 'prop-types';

import Button from 'core/components/Button.js';

import autobind from 'common/decorators/autobind.js';
import {PracticeStoreContext} from 'core/stores/RootStore.js';
import {CALL_NOW_BTN_PRIMARY, CALL_NOW_BTN_SECONDARY} from 'core/constants';

export default class CallNowButton extends React.Component {
    static contextType = PracticeStoreContext;

    static propTypes = {
        contactType: PropTypes.string.isRequired,
        customClass: PropTypes.string,
        icon: PropTypes.string,
        showModal: PropTypes.func,
    };

    @autobind
    showModal() {
        if (this.props.showModal) {
            this.props.showModal();
        } else {
            this.context.showModal('contact');
        }
    }

    render() {
        const {customClass, icon} = this.props;
        return (
            <Button
                action={this.showModal}
                customClass={`cta ${customClass || ''}`.trim()}
                gaSelector={
                    customClass === 'positive'
                        ? CALL_NOW_BTN_PRIMARY
                        : CALL_NOW_BTN_SECONDARY
                }
                icon={icon}
                text="Call now"
            />
        );
    }
}
