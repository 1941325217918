import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'core/components/Icon.js';
import Link from 'core/components/Link.js';

export default class PrintLink extends React.Component {
    static propTypes = {
        url: PropTypes.string.isRequired,
    };

    render() {
        const {url} = this.props;

        if (!url) {
            return null;
        }

        return (
            <Link href={url} isExternalLink={true}>
                <Icon name="print" />
                {'Print'}
            </Link>
        );
    }
}
