import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'core/components/Icon.js';
import Link from 'core/components/Link.js';

export default class RelatedInfo extends React.Component {
    static propTypes = {
        communities: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                numberOfPractitioners: PropTypes.number.isRequired,
                numberOfQa: PropTypes.number.isRequired,
                url: PropTypes.string.isRequired,
            }),
        ),
        factsheets: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                sponsor: PropTypes.string.isRequired,
                url: PropTypes.string.isRequired,
            }),
        ),
        name: PropTypes.string.isRequired,
    };

    renderCommunity(community) {
        const {id, name, numberOfPractitioners, numberOfQa, url} = community;
        // eslint-disable-next-line max-len
        const description = `${numberOfPractitioners} health practitioners and ${numberOfQa} Q&As`;
        return (
            <li key={id}>
                <Link href={url}>
                    <Icon name="communities" />
                    <strong>{name}</strong>
                    {description}
                </Link>
            </li>
        );
    }

    renderRelatedCommunities() {
        const {communities, name} = this.props;
        const communityBlurb = `
            Our Health Communities related to ${name}'s field of practice.
            These provide you with information on a range of conditions and
            healthcare approaches.`;
        if (communities?.length) {
            return (
                <>
                    <h1>Related health communities</h1>
                    <p>{communityBlurb}</p>
                    <ul className="feature-list communities">
                        {communities.map((community) =>
                            this.renderCommunity(community),
                        )}
                    </ul>
                </>
            );
        }
        return null;
    }

    renderFactsheet(factsheet) {
        const {id, name, sponsor, url} = factsheet;
        return (
            <li key={id}>
                <Link href={url}>
                    <Icon name="factsheets" />
                    <strong>{name}</strong>
                    {sponsor}
                </Link>
            </li>
        );
    }

    renderRelatedFactsheets() {
        const {factsheets, name} = this.props;
        const factsheetsBlurb = `
            Concise and verified healthcare information related
            to ${name}'s field of practice. These cover conditions,
            treatments, and health products in an easy-to-read,
            high-quality format.`;
        if (factsheets?.length) {
            return (
                <>
                    <h1>Related factsheets</h1>
                    <p>{factsheetsBlurb}</p>
                    <ul className="feature-list factsheets">
                        {factsheets.map((factsheet) =>
                            this.renderFactsheet(factsheet),
                        )}
                    </ul>
                </>
            );
        }
        return null;
    }

    render() {
        return (
            <>
                {this.renderRelatedFactsheets()}
                {this.renderRelatedCommunities()}
            </>
        );
    }
}
