import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';

import autobind from 'common/decorators/autobind.js';
import Button from 'core/components/Button.js';
import Icon from 'core/components/Icon.js';
import Link from 'core/components/Link.js';
import Modal from 'core/components/Modal.js';
import PrintLink from 'core/components/PrintLink.js';
import ReportModal from 'core/components/ReportModal.js';
import {
    PAGE_TYPE_PRACTICE_GROUP,
    PAGE_TYPE_PRACTICE_LOCATION,
    PAGE_TYPE_PRACTITIONER,
} from '../constants';

export default class Tools extends React.Component {
    static propTypes = {
        availableCategories: PropTypes.arrayOf(PropTypes.string.isRequired)
            .isRequired,
        customClass: PropTypes.string,
        displayName: PropTypes.string.isRequired,
        isClient: PropTypes.bool.isRequired,
        pageType: PropTypes.string.isRequired,
        pdfUrl: PropTypes.string,
    };

    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
        };
    }

    @autobind
    closeReportModal() {
        this.setState({showModal: false});
    }

    @autobind
    showReportModal() {
        this.setState({showModal: true});
    }

    get querySelector() {
        switch (this.props.pageType) {
            default:
            case PAGE_TYPE_PRACTITIONER:
                return '#professional-profile';
            case PAGE_TYPE_PRACTICE_LOCATION:
                return '#practice-location';
            case PAGE_TYPE_PRACTICE_GROUP:
                return '#practice-group';
        }
    }

    // For now I will keep it on the Tools component
    // If needed can be controlled by the parent
    renderReportModal() {
        const {
            availableCategories,
            displayName,
            isClient,
            pageType,
        } = this.props;
        const activeElement = document.activeElement;
        return ReactDOM.createPortal(
            <Modal
                closeModal={this.closeReportModal}
                initElement={activeElement}
                parentElement={activeElement.parentElement}
            >
                <ReportModal
                    availableCategories={availableCategories}
                    closeModal={this.closeReportModal}
                    displayName={displayName}
                    isClient={isClient}
                    pageType={pageType}
                />
            </Modal>,
            document.querySelector(this.querySelector),
        );
    }

    renderText() {
        return (
            <>
                <Icon name="report" />
                <span>{'Report issue'}</span>
            </>
        );
    }

    render() {
        const {pdfUrl} = this.props;
        return (
            <section
                className={`tools ${this.props.customClass || ''}`.trim()}
            >
                {pdfUrl && <PrintLink url={pdfUrl} />}
                <Button
                    action={this.showReportModal}
                    customClass="button-handle"
                    text={this.renderText()}
                    title="Report an issue on this page"
                />
                {this.state.showModal && this.renderReportModal()}
            </section>
        );
    }
}
