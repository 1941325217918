import React from 'react';
import PropTypes from 'prop-types';

import CollapsibleText from 'core/components/CollapsibleText.js';
import OpenIndicator from 'core/components/OpenIndicator.js';

export default class ExtraInfo extends React.Component {
    static proptypes = {
        comment: PropTypes.string,
        disabledAccess: PropTypes.bool,
        displayIndicator: PropTypes.bool,
        onsiteParking: PropTypes.bool,
        openHours: PropTypes.array,
        openIndicator: PropTypes.string,
        paymentOptions: PropTypes.array,
        serviceOptions: PropTypes.string,
    };

    static defaultProps = {
        displayIndicator: true,
    };

    formatHours(hours) {
        return hours.map((obj) => {
            const {day, hours} = obj;
            return (
                <tr key={`${day}-${hours}`}>
                    <td>{day}</td>
                    <td>{hours}</td>
                </tr>
            );
        });
    }

    renderTableHeader() {
        const {displayIndicator, openIndicator} = this.props;

        if (displayIndicator && openIndicator) {
            return (
                <th colSpan="2">
                    <OpenIndicator isOpen={openIndicator}>
                        {'Open hours'}
                    </OpenIndicator>
                </th>
            );
        }
        return <th colSpan="2">{'Open hours'}</th>;
    }

    render() {
        const {
            comment,
            disabledAccess,
            openHours,
            onsiteParking,
            paymentOptions,
            serviceOptions,
        } = this.props;

        if (
            !comment &&
            !disabledAccess &&
            !openHours?.length &&
            !onsiteParking &&
            !paymentOptions?.length &&
            !serviceOptions
        ) {
            return null;
        }

        return (
            <div className="extra-info">
                {!!openHours?.length && (
                    <table>
                        <thead>
                            <tr>{this.renderTableHeader()}</tr>
                        </thead>
                        <tbody>{this.formatHours(openHours)}</tbody>
                    </table>
                )}
                {comment && <CollapsibleText maxWords={30} text={comment} />}
                <dl>
                    {serviceOptions && (
                        <>
                            <dt>{'Service options:'}</dt>
                            <dd>{serviceOptions}</dd>
                        </>
                    )}
                    {!!paymentOptions?.length && (
                        <>
                            <dt>{'Payment options: '}</dt>
                            <dd>{paymentOptions.join(', ')}</dd>
                        </>
                    )}
                    {disabledAccess && (
                        <>
                            <dt>{'Disabled access: '}</dt>
                            <dd>{'Available'}</dd>
                        </>
                    )}
                    {onsiteParking && (
                        <>
                            <dt>{'Parking on-site: '}</dt>
                            <dd>{'Available'}</dd>
                        </>
                    )}
                </dl>
            </div>
        );
    }
}
