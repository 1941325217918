import PropTypes from 'prop-types';
import React from 'react';

import autobind from 'common/decorators/autobind.js';
import Dropdown from 'core/components/Dropdown.js';
import Icon from 'core/components/Icon.js';
import Button from 'core/components/Button.js';
import {HealthFundStoreContext} from 'core/stores/RootStore.js';
import {observer} from 'mobx-react';
import {SELECT_HEALTH_FUND_BTN} from 'core/constants';

export default
@observer
class HealthFundPicker extends React.Component {
    static contextType = HealthFundStoreContext;

    static propTypes = {
        handleMouseDown: PropTypes.func,
        handleSelectHealthFund: PropTypes.func,
        handleToggle: PropTypes.func,
        initialOptionText: PropTypes.string,
        isHidden: PropTypes.bool,
        isMedical: PropTypes.bool,
        stopAutoScroll: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        };
    }

    @autobind
    healthFundHandler(hf) {
        const {handleSelectHealthFund} = this.props;
        this.setState((state) => ({
            isOpen: !state.isOpen,
        }));
        handleSelectHealthFund(hf);
    }

    @autobind
    handleMouseDown(event) {
        const {handleMouseDown} = this.props;
        if (handleMouseDown) {
            handleMouseDown(event);
        }
    }

    @autobind
    handleToggle(status) {
        this.setState({
            isOpen: status,
        });
        const {handleToggle} = this.props;
        if (handleToggle) {
            handleToggle(status);
        }
    }

    healthFundList() {
        const {initialOptionText} = this.props;
        const {healthFunds} = this.context;
        const options = [
            {
                name: 'initial',
                content: (
                    <>
                        <Button
                            action={() => this.healthFundHandler({})}
                            text={initialOptionText}
                        />
                        <span className="separator" />
                    </>
                ),
            },
        ];
        healthFunds?.forEach((hf) =>
            options.push({
                name: hf.name,
                content: (
                    <Button
                        action={() => this.healthFundHandler(hf)}
                        text={
                            <>
                                <Icon name="checkmark" />
                                <div
                                    style={{
                                        backgroundImage: `url("${hf.avatar}")`,
                                    }}
                                />
                                {hf.name}
                            </>
                        }
                    />
                ),
            }),
        );
        return options;
    }

    get pickerTitle() {
        const {isMedical} = this.props;
        const {
            rootStore: {
                paramStore: {isReferrals},
            },
        } = this.context;

        if (isReferrals) {
            return isMedical
                ? 'Your patient’s medical costs may be reduced when the practitioner uses a health fund gap scheme for hospital treatment.'
                : 'Your patient’s treatment costs may be reduced when the practitioner participates in a health fund member arrangement.';
        }
        return 'Your treatment costs may be reduced when the practitioner participates in a health fund member arrangement.';
    }

    render() {
        const {healthFund} = this.context;
        const {isHidden, children, stopAutoScroll} = this.props;

        if (isHidden) {
            return null;
        }
        return (
            <form aria-selected={!!healthFund?.id} className="fund-info">
                <Dropdown
                    customClass="phi-picker"
                    gaSelector={SELECT_HEALTH_FUND_BTN}
                    displayText={healthFund?.name || 'Select health fund'}
                    handleMouseDown={this.handleMouseDown}
                    handleToggle={this.handleToggle}
                    options={this.healthFundList()}
                    selectedOption={healthFund?.name}
                    storeIsOpen={this.state.isOpen}
                    stopAutoScroll={stopAutoScroll}
                />
                <span>{this.pickerTitle}</span>
                {children}
            </form>
        );
    }
}
