import PropTypes from 'prop-types';
import React from 'react';

export default class OpenIndicator extends React.Component {
    static propTypes = {
        children: PropTypes.string.isRequired,
        displayIndicatorText: PropTypes.bool,
        isOpen: PropTypes.string,
    };

    static defaultProps = {
        displayIndicatorText: true,
    };

    indicatorClass() {
        const {isOpen} = this.props;
        let cssClass = 'open-indicator';
        switch (isOpen) {
            case 'opening soon':
            case 'closing soon':
                cssClass += ' soon';
                break;
            case 'open now':
                cssClass += ' open';
                break;
            default:
                cssClass = undefined;
        }
        return cssClass;
    }

    indicatorText() {
        const {displayIndicatorText, isOpen} = this.props;
        if (!displayIndicatorText) {
            return '';
        }
        switch (isOpen) {
            case 'opening soon':
            case 'closing soon':
            case 'open now':
                return ` (${isOpen})`;
            default:
                return '';
        }
    }

    render() {
        return (
            <span className={this.indicatorClass()}>
                {`${this.props.children}${this.indicatorText()}`}
            </span>
        );
    }
}
