import React from 'react';
import PropTypes from 'prop-types';

import autobind from 'common/decorators/autobind.js';
import http from 'http.js';

import Icon from 'core/components/Icon.js';

export default class Video extends React.Component {
    static propTypes = {
        profileId: PropTypes.number.isRequired,
        preventTracking: PropTypes.bool,
        video: PropTypes.shape({
            link: PropTypes.string.isRequired,
            thumbnail: PropTypes.string,
        }).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            imgLoaded: false,
            showThumbnail: true,
        };
        this.thumbnail = React.createRef();
    }

    @autobind
    onPlayVideo() {
        if (!this.props.preventTracking) {
            this.trackVideo();
        }
        this.setState({
            showThumbnail: false,
        });
    }

    async trackVideo() {
        const {
            video: {link},
            profileId,
        } = this.props;
        http.post({
            data: {
                'professional_profile': profileId,
                link,
            },
            url: '/api/professionals/v1/events/professional-video-views/',
        });
    }

    render() {
        const {video} = this.props;
        if (this.state.showThumbnail && video.thumbnail) {
            return (
                <button
                    className="video"
                    name="play video"
                    onClick={this.onPlayVideo}
                >
                    <img
                        alt="relevant video"
                        className="thumbnail"
                        onLoad={() => this.setState({imgLoaded: true})}
                        ref={this.thumbnail}
                        src={video.thumbnail}
                    />
                    {this.state.imgLoaded && <Icon name="play" />}
                </button>
            );
        } else {
            return (
                <iframe
                    className="video"
                    height={this.thumbnail.current?.height}
                    src={video.link}
                    width={this.thumbnail.current?.width}
                />
            );
        }
    }
}
