import React from 'react';
import PropTypes from 'prop-types';

export default class ImageGallery extends React.Component {
    static propTypes = {
        images: PropTypes.arrayOf(
            PropTypes.shape({
                thumbnailUrl: PropTypes.string.isRequired,
                fullSizeUrl: PropTypes.string.isRequired,
            }),
        ),
        onClick: PropTypes.func.isRequired,
    };

    render() {
        const {images, onClick} = this.props;
        if (!images.length) {
            return null;
        }
        return (
            <figure className="gallery">
                {images.map((img, index) => {
                    return (
                        <img
                            key={index}
                            onClick={() => onClick(index)}
                            src={img.retinaThumbnailUrl}
                            srcSet={`${img.thumbnailUrl} 1x, ${img.retinaThumbnailUrl} 2x`}
                        />
                    );
                })}
            </figure>
        );
    }
}
